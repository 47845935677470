export const routes = [
  {
    type: "admin",
    path: "/",
    component: () => import("$src/views/admin/Admin.vue"),
    children: [
      {
        path: "materials",
        title: "Materials",
        icon: ["fas", "boxes"],
        component: () =>
          import("$src/views/admin/resources/materials/AdminMaterials.vue"),
      },
      {
        path: "fillers",
        title: "Fillers",
        icon: ["fas", "boxes"],
        component: () =>
          import("$src/views/admin/resources/fillers/Fillers.vue"),
      },
      {
        path: "fluxes",
        title: "Fluxes",
        icon: ["fas", "boxes"],
        component: () => import("$src/views/admin/resources/fluxes/Fluxes.vue"),
      },
      {
        path: "clients",
        title: "Clients",
        icon: ["fas", "users"],
        component: () => import("$src/views/admin/clients/Clients.vue"),
      },
    ],
  },
  {
    type: "enterprise",
    path: "/:enterprise",
    component: () => import("$src/views/enterprise/Enterprise.vue"),
    children: [
      {
        hide: true,
        path: "myOrganization",
        title: "My Organization",
        privilege: "COMP_PROFILE_VIEW",
        component: () =>
          import("$src/views/enterprise/organization/Organization.vue"),
      },
      {
        hide: true,
        path: "users",
        title: "Users",
        privilege: "USERS_VIEW",
        component: () => import("$src/views/enterprise/users/Users.vue"),
        children: [
          {
            path: ":id",
            component: () => import("$src/views/enterprise/users/User.vue"),
          },
        ],
      },
      {
        path: "dashboard",
        title: "Dashboard",
        icon: ["fas", "chart-line"],
        component: () =>
          import("$src/views/enterprise/dashboard/Dashboard.vue"),
      },
      {
        path: "projects",
        title: "Projects",
        icon: ["fas", "sitemap"],
        privilege: "PROJECTS_VIEW",
        component: () => import("$src/views/enterprise/projects/Projects.vue"),
        children: [
          {
            path: "project/:id",
            title: "Project",
            privilege: ["PROJECTS_ADD", "PROJECTS_EDIT"],
            component: () =>
              import("$src/views/enterprise/projects/ProjectsView.vue"),
          },
          {
            path: ":id",
            title: "Drawings",
            privilege: "PROJECTS_VIEW",
            component: () =>
              import("$src/views/enterprise/drawings/Drawings.vue"),
            children: [
              {
                path: "create",
                title: "Drawings",
                privilege: "DRAWINGS_ADD",
                component: () =>
                  import("$src/views/enterprise/drawings/DrawingsCreate.vue"),
              },
              {
                path: "drawing/:drawingId",
                title: "Drawing",
                privilege: "PROJECTS_VIEW",
                component: () =>
                  import("$src/views/enterprise/drawing/Drawing.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "qcps",
        title: "QCP's",
        icon: ["fas", "file-invoice"],
        privilege: "QCP_VIEW",
        component: () => import("$src/views/enterprise/qcps/Qcps.vue"),
        children: [
          {
            privilege: "QCP_VIEW",
            path: ":id",
            component: () => import("$src/views/enterprise/qcps/QcpView.vue"),
          },
        ],
      },
      {
        path: "pqr",
        title: "PQR's",
        icon: ["fas", "file-invoice"],
        privilege: "PQR_VIEW",
        component: () => import("$src/views/enterprise/pqrs/Pqrs.vue"),
        children: [
          {
            privilege: ["PQR_UPLOAD", "PQR_EDIT"],
            path: ":id",
            component: () => import("$src/views/enterprise/pqrs/PqrView.vue"),
          },
        ],
      },
      {
        path: "wps",
        title: "WPS's",
        icon: ["fas", "file-invoice"],
        privilege: "WPS_VIEW",
        component: () => import("$src/views/enterprise/wps/Wps.vue"),
        children: [
          {
            privilege: ["WPS_ADD", "WPS_EDIT"],
            path: ":id",
            component: () => import("$src/views/enterprise/wps/WpsView.vue"),
          },
        ],
      },
      {
        path: "qualifications",
        title: "Welder Qualifications",
        icon: ["fas", "id-card"],
        privilege: "WELDER_QUAL_VIEW",
        component: () =>
          import("$src/views/enterprise/qualifications/Qualifications.vue"),
        children: [
          {
            path: ":id",
            privilege: "WELDER_QUAL_CREATE",
            component: () =>
              import(
                "$src/views/enterprise/qualifications/QualificationsView.vue"
              ),
          },
          {
            path: "logs/maintenance-us",
            privilege: "WELDER_QUAL_VIEW",
            component: () =>
              import(
                "$src/views/enterprise/qualifications/logs/MaintenanceLogUs.vue"
              ),
          },
          {
            path: "logs/welder-us",
            privilege: "WELDER_QUAL_VIEW",
            component: () =>
              import(
                "$src/views/enterprise/qualifications/logs/WelderLogUs.vue"
              ),
          },
          {
            path: "logs/qualification-eu",
            privilege: "WELDER_QUAL_VIEW",
            component: () =>
              import(
                "$src/views/enterprise/qualifications/logs/QualificationLogEu.vue"
              ),
          },
        ],
      },
      {
        path: "templates",
        title: "Templates",
        icon: ["fas", "file-lines"],
        privilege: "TEMPLATE_VIEW",
        component: () =>
          import("$src/views/enterprise/templates/Templates.vue"),
        children: [
          {
            path: ":id",
            privilege: ["TEMPLATE_ADD", "TEMPLATE_EDIT"],
            component: () =>
              import("$src/views/enterprise/templates/TemplatesView.vue"),
          },
        ],
      },
      {
        title: "Human Resources",
        icon: ["fas", "users"],
        privilege: "EMPLOYEES_VIEW",
        children: [
          {
            path: "employees",
            title: "Employees",
            icon: ["fas", "user-friends"],
            privilege: "EMPLOYEES_VIEW",
            component: () =>
              import("$src/views/enterprise/employees/Employees.vue"),
            children: [
              {
                path: "add",
                privilege: "EMPLOYEES_ADD",
                component: () =>
                  import("$src/views/enterprise/employees/EmployeesAdd.vue"),
              },
              {
                path: ":id",
                privilege: "EMPLOYEES_VIEW",
                component: () =>
                  import("$src/views/enterprise/employees/EmployeesView.vue"),
              },
            ],
          },
          {
            title: "Training",
            path: "training",
            icon: ["fas", "graduation-cap"],
            privilege: "EMPLOYEES_VIEW",
            component: () =>
              import("$src/views/enterprise/employees/EmployeeTraining.vue"),
          },
        ],
      },
      {
        title: "Assets",
        icon: ["fas", "boxes-packing"],
        privilege: [
          "ASSET_MANAGEMENT_VIEW",
          "MATERIALS_VIEW",
          "EQUIPMENT_VIEW",
          "CONSUMABLES_VIEW",
        ],
        children: [
          {
            path: "assets",
            title: "Asset Management",
            icon: ["fas", "dolly"],
            privilege: "ASSET_MANAGEMENT_VIEW",
            component: () =>
              import("$src/views/enterprise/assetManagement/PackingLists.vue"),
            children: [
              {
                path: ":id",
                privilege: "ASSET_MANAGEMENT_VIEW",
                component: () =>
                  import(
                    "$src/views/enterprise/assetManagement/PackingListView.vue"
                  ),
              },
              {
                path: ":id/:number/items",
                privilege: "ASSET_MANAGEMENT_VIEW",
                component: () =>
                  import(
                    "$src/views/enterprise/assetManagement/PackingListItems.vue"
                  ),
              },
            ],
          },
          {
            path: "materials",
            title: "Materials",
            icon: ["fas", "boxes"],
            privilege: "MATERIALS_VIEW",
            component: () =>
              import("$src/views/enterprise/materials/Materials.vue"),
            children: [
              {
                path: ":id",
                component: () =>
                  import("$src/views/enterprise/materials/MaterialsView.vue"),
              },
            ],
          },
          {
            path: "equipment",
            title: "Equipment",
            icon: ["fas", "screwdriver-wrench"],
            privilege: "EQUIPMENT_VIEW",
            component: () =>
              import("$src/views/enterprise/equipment/Equipment.vue"),
            children: [
              {
                path: ":id",
                privilege: ["EQUIPMENT_VIEW"],
                component: () =>
                  import("$src/views/enterprise/equipment/EquipmentView.vue"),
              },
            ],
          },
          {
            path: "consumables",
            title: "Consumables",
            icon: ["fas", "box"],
            privilege: "CONSUMABLES_VIEW",
            component: () =>
              import("$src/views/enterprise/consumables/Consumables.vue"),
            children: [
              {
                path: ":id",
                privilege: ["CONSUMABLES_ADD", "CONSUMABLES_EDIT"],
                component: () =>
                  import(
                    "$src/views/enterprise/consumables/ConsumablesView.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "tradingPartners",
        title: "Trading Partners",
        icon: ["fas", "store"],
        privilege: "VENDORS_VIEW",
        component: () => import("$src/views/enterprise/vendors/Vendors.vue"),
        children: [
          {
            path: ":id",
            privilege: ["VENDORS_ADD", "VENDORS_EDIT"],
            component: () =>
              import("$src/views/enterprise/vendors/VendorsView.vue"),
          },
        ],
      },
      {
        title: "ISO 3834",
        icon: ["fas", "award"],
        children: [
          {
            path: "iso3834/training",
            title: "ISO 3834 Training",
            icon: ["fas", "graduation-cap"],
            component: () =>
              import("$src/views/enterprise/iso3834/Iso3834Training.vue"),
          },
          {
            path: "iso3834/templates",
            title: "ISO 3834 Templates",
            icon: ["fas", "file"],
            component: () =>
              import("$src/views/enterprise/iso3834/Iso3834Templates.vue"),
          },
        ],
      },
      {
        path: "documents",
        title: "Documents",
        icon: ["fas", "folder"],
        privilege: "DOCUMENTS_VIEW",
        component: () =>
          import("$src/views/enterprise/documents/Documents.vue"),
        children: [
          {
            path: ":id",
            privilege: ["DOCUMENTS_ADD", "DOCUMENTS_EDIT"],
            component: () =>
              import("$src/views/enterprise/documents/DocumentsView.vue"),
          },
        ],
      },
      {
        path: "forms",
        title: "Forms",
        icon: ["fas", "file"],
        privilege: "FORM_VIEW",
        component: () => import("$src/views/enterprise/forms/Forms.vue"),
        children: [
          {
            path: ":tId/:id",
            privilege: "FORM_VIEW",
            component: () =>
              import("$src/views/enterprise/forms/FormsView.vue"),
          },
        ],
      },
      // {
      //   title: "SAIW",
      //   icon: ["fas", "award"],
      //   children: [
      //     {
      //       path: "saiw/application",
      //       title: "Application",
      //       icon: ["fas", "graduation-cap"],
      //       component: () =>
      //         import("$src/views/enterprise/saiw/ApplicationForm.vue"),
      //     },
      //     {
      //       path: "saiw/audit",
      //       title: "Audits",
      //       icon: ["fas", "graduation-cap"],
      //       component: () =>
      //         import("$src/views/enterprise/saiw/AuditReport.vue"),
      //     },
      //   ],
      // },
    ],
  },
];
